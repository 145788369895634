import React, { useState, useEffect } from "react";
import { useEventID } from "wecall-config-lib";
import logo from "../assets/logo.png";
import star from "../assets/star.png";
import { Link, Outlet, Route, Routes } from "react-router-dom";
import arrow from "../assets/arrow.svg";
import testimonial from "../assets/review-main.png";
import user1 from "../assets/user-1.jpeg";
import user2 from "../assets/user-2.jpeg";
import user3 from "../assets/user-3.jpeg";
import user4 from "../assets/user-4.jpeg";
import InitialScripts from "../script/initialScripts";
import FloatingCard from "../floating-card";
import gasImage from "../assets/gas.webp";
import groceryImage from "../assets/food.webp";
import rentImage from "../assets/rent.webp";
import shoppingImage from "../assets/bills.webp";
import trustseal from "../assets/trust-seal.png";

export default function DefaultPage({ setNumber, cityStateResponse }) {
  const { state, city, zip } = cityStateResponse;
  return (
    <>
      <InitialScripts setNumber={setNumber} />
      <div className="App">
        <div className="mainWrapper">
          <header>
            <div className="container">
              <div className="row">
                <div className="logo-wrapper">
                  <a href="#">
                    <img src={logo}></img>
                  </a>
                </div>
              </div>
            </div>
          </header>
          <div className="hero-section">
            <div className="container">
              <div className="row">
                <Outlet />
              </div>
            </div>
          </div>
          <div className="trusted-seals">
            <div className="container">
              <div className="row">
                <div className="trusted-seal-headline">
                  <h2>Trusted Partners, Secured Promise</h2>
                </div>
                <div className="trusted-seal-wrapper">
                  <img src={trustseal} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="claims">
            <div className="container">
              <div className="row">
                <div className="claim-headline-wrapper">
                  <div className="claim-headline-left">
                    <h2>Transforming Lives: The Impact of the Subsidy Program</h2>
                  </div>
                  <div className="claim-headline-right">
                    <p>Dive into the remarkable journey of the Subsidy Program, a pivotal initiative reshaping the financial landscape for low-wage Americans. Witness the significant strides made in providing essential support for groceries, rent, gas, and bills. Here's a snapshot of the monumental impact and ongoing progress:</p>
                  </div>
                </div>
                <div className="item-container">
                  <div className="claim-item">
                    <div className="bignumber">
                      $43 Million
                    </div>
                    <div className="smalltext">
                      <p>The ripple effect of financial empowerment is evident, with millions allocated to uplift households across the nation.</p>
                    </div>
                  </div>
                  <div className="claim-item">
                    <div className="bignumber">
                      250,000+
                    </div>
                    <div className="smalltext">
                      <p>Many lives have been touched, with more families joining every day to avail the comprehensive benefits.</p>
                    </div>
                  </div>
                  <div className="claim-item">
                    <div className="bignumber">
                      1 in 3
                    </div>
                    <div className="smalltext">
                      <p>Reflecting the widespread need, a third of the population qualifies for this life-enhancing program, ensuring no one is left behind.</p>
                    </div>
                  </div>
                  <div className="claim-item">
                    <div className="bignumber">
                      482 Seats
                    </div>
                    <div className="smalltext">
                      <p>With limited spots remaining, urgency meets opportunity for those aiming to harness the program's full potential.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section1">
            <div className="container">
              <div className="row">
                <div className="section-1-content">
                  <h1>Less than 24 hours left to enroll in benefits in {cityStateResponse.state}</h1>
                  <p>Thanks to a new government subsidiary program, {cityStateResponse.state} residents could get $0 Free Health Insurance and up to $1400* allowance card for the everyday expense including No-Cost Vision & No-Cost Dental Benefits.</p>
                </div>
                <div className="item-benefits">
                  <ul>
                    <li>
                      <div className="benefit">
                        <div className="benefitHeadline">
                          <h3>Groceries</h3>
                          <p>Make your grocery budget go further with the $6400 Subsidy Card. Enjoy the freedom to fill your cart with essentials and more, without the pinch on your wallet.</p>
                        </div>
                        <div className="benefitImage">
                          <img src={groceryImage}></img>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="benefit">
                        <div className="benefitHeadline">
                          <h3>Rent</h3>
                          <p>Stabilize your living situation with our Subsidy Card. Relieve the pressure of rent and focus on what truly matters in your home and life.</p>
                        </div>
                        <div className="benefitImage">
                          <img src={rentImage}></img>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="benefit">
                        <div className="benefitHeadline">
                          <h3>Gas</h3>
                          <p>Keep your tank full and your travels worry-free. The Subsidy Card takes the edge off commuting costs, fueling your way to work and beyond.</p>
                        </div>
                        <div className="benefitImage">
                          <img src={gasImage}></img>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="benefit">
                        <div className="benefitHeadline">
                          <h3>Bills</h3>
                          <p>Manage your utility and other bills with ease. The Subsidy Card is your ally in keeping the lights on and the water running without financial stress.</p>
                        </div>
                        <div className="benefitImage">
                          <img src={shoppingImage}></img>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="testimonial">
            <div className="container">
              <div className="row">
                <div className="testimonial-headline">
                  <h1>Discover the Difference Today!</h1>
                  <p>Join a community of satisfied customers, evidenced by our outstanding Trustpilot rating. Experience our unparalleled service in just a click. See why we're the preferred choice!</p>
                </div>
                <div className="testimonial-content-holder">
                  <img src={testimonial} alt="" />
                </div>
                <div className="testimonial-list">
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-top">
                        <div className="testimonial-left">
                          <img src={user1}></img>
                          <h3>Floyd Miles</h3>
                        </div>
                      </div>
                      <div className="testimonial-bottom">
                        <p>
                          I just received mine fast! They worked tirelessly to
                          secure the financial subsidy I needed, and their
                          dedication to customer satisfaction is unmatched.
                        </p>
                        <img src={star} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-top">
                        <div className="testimonial-left">
                          <img src={user2}></img>
                          <h3>Michael Anderson</h3>
                        </div>
                      </div>
                      <div className="testimonial-bottom">
                        <p>
                        Just in time when i need it the most! I can't thank US Support enough for their prompt assistance. Their team's commitment to me is truly commendable
                        </p>
                        <img src={star} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-top">
                        <div className="testimonial-left">
                          <img src={user3}></img>
                          <h3>Olivia Martinez</h3>
                        </div>
                      </div>
                      <div className="testimonial-bottom">
                        <p>
                          I was in a financial bind, and US Support came to the rescue. Their team's dedication and service to people are top-notch, and I couldn't be happier with their help.
                        </p>
                        <img src={star} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="container top-footer">
              <div className="row">
                <div className="footer-content">
                  <p>
                    {window.domain_settings.websiteName} is not an insurance or
                    operating company but connects individuals with insurance
                    providers and other affiliates. Plans are insured or covered
                    by a Medicare Advantage organization with a Medicare
                    contract and/or a Medicare-approved Part D
                    sponsor.Enrollment in the plan depends on the plan’s
                    contract renewal with Medicare. Possible options include,
                    but are not limited to Major Medical Plans, Short Term
                    Plans, Christian Health Plans, Health Sharing Plans,
                    discount cards and Fixed Indemnity Plans.Descriptions are
                    for informational purposes only and subject to change.
                    {" " + window.domain_settings.websiteName} is not affiliated
                    with any insurance plan nor does it represent or endorse any
                    plan. We do not offer every plan available in your area. Any
                    information we provide is limited to those plans we do offer
                    in your area. Please contact Medicare.gov or 1–800 MEDICARE
                    to get information on all of your options. By using this
                    site, you acknowledge that you have read and agree to the
                    Terms of Service, and Privacy Policy. Not affiliated with
                    the U. S. government or federal Medicare program. A licensed
                    agent may contact you regarding this insurance-related
                    information.
                  </p>
                </div>
              </div>
            </div>
            <div className="container bottom-footer">
              <div className="row">
                <div className="footer-navigation">
                  <div className="footer-copyright">
                    <p>© Copyright {window.domain_settings.websiteTitle} 2023</p>
                  </div>
                  <div className="footer-navigation-wrapper">
                    <ul>
                      <li>
                        <a href="/privacy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/terms">Terms of Use</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FloatingCard />
      <LanderSocialEventTrigger />
    </>
  );
}

function LanderSocialEventTrigger() {
  const { getEventId } = useEventID();

  const viewContentKey = "PageView";
  const viewContentKey2 = "PageView2";

  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);

  useEffect(() => {
    if (window.fbcFunc) {
      window.fbcFunc("track", "PageView", { eventID: getEventId() });
      sessionStorage.setItem(viewContentKey, "true");
    }

    if (window.tikTokEvent && params.get("utm_source") === "tiktok") {
      window.tikTokEvent.track("ViewContent", {}, { eventID: getEventId() });
      sessionStorage.setItem(viewContentKey2, "true");
    }
  }, []);
  return <></>;
}
